import React, { useEffect, useState } from 'react';
import { Table, message, Checkbox} from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import ThreeTablesModal from "./SearchResultModal";
import { useSelector, useDispatch } from 'react-redux';

const Opportunities = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const dispatch = useDispatch();

  const formatAlertText = (text) => {
    if (!text) return null;
  
    const becauseIndex = text.indexOf('because:');
    const formattedText = becauseIndex !== -1
      ? `${text.substring(0, becauseIndex + 8)}\n${text
          .substring(becauseIndex + 8)
          .replace(/ - /g, '\n- ')
          .replace(/\n+/g, '\n')
          .trim()}`
      : text;
  
    return formattedText.split('\n').map((line, index) => {
      if (line.startsWith('- (initiated') || line.startsWith('- (updated')) {
        // Metadata line
        return (
          <div
            key={index}
            style={{
              color: 'gray',
              fontStyle: 'italic',
              fontSize: '10px',
            }}
          >
            {line.replace(/^- /, '')}
          </div>
        );
      } else if (line.startsWith('-')) {
        // Bullet point
        return (
          <div key={index} className="bullet-container">
            {line.replace(/^- /, '')}
          </div>
        );
      }
      // Default line
      return <div key={index}>{line}</div>;
    });
  };
  

  const handleRowClick = (record) => {
    setSelectedTicker(record.ticker); // Assuming `ticker` is the unique identifier
    setIsModalOpen(true);
  };
  

  const fetchOpportunities = async () => {
    const userId = getUserId(); // Get the logged-in user's ID
    setLoading(true);
    try {
      const response = await api.post('/get_alerts_unknown_scope', { userId });
      console.log('Opportunities alerts:', response.data.alerts);
      setData((response.data.alerts || []).sort((a, b) => a.seen - b.seen));

      // setData(response.data.alerts || []);
    } catch (error) {
      console.error('Error fetching opportunities alerts:', error);
      message.error('Failed to fetch opportunities alerts.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const handleCheckboxChange = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.post('/pf_alert_check_update', { id, seen: newStatus });
      dispatch(updateSeenStatus(id, newStatus));
  
      // Update the local `data` state to reflect the changes
      setData((prevData) =>
        prevData
          .map((item) => (item.ID === id ? { ...item, seen: newStatus } : item))
          .sort((a, b) => a.seen - b.seen)
      );
      
      
    } catch (error) {
      console.error('Failed to update seen status', error);
      message.error('Failed to update checkbox status.');
    }
  };
  

  // Redux action to update the 'seen' status
    const updateSeenStatus = (id, seen) => {
      return {
        type: 'UPDATE_SEEN_STATUS',
        payload: { id, seen },
      };
    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'ID',
        key: 'ID',
      },
      {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        render: (text, record) => (
          <span
            style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => handleRowClick(record)}
          >
            {text}
          </span>
        ),
      },
      {
        title: 'Alert Text',
        dataIndex: 'pf_alert_text',
        key: 'pf_alert_text',
        render: (text) => <div>{formatAlertText(text)}</div>,
      },
      {
        title: 'Alert Type',
        dataIndex: 'alert_type',
        key: 'alert_type',
      },
      {
        title: 'Alert Subtype',
        dataIndex: 'alert_subtype',
        key: 'alert_subtype',
      },
      {
        title: 'Checked?',
        dataIndex: 'seen',
        key: 'seen',
        width: 10,
        render: (text, record) => (
          <Checkbox 
            checked={record.seen === 1} 
            onChange={() => handleCheckboxChange(record.ID, record.seen)}
          />
        ),
      },
    ];
    

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="ID"
        loading={loading}
        pagination={{ pageSize: 10 }}
        // onRow={(record) => ({
        //   onClick: () => handleRowClick(record),
        // })}
      />
      <ThreeTablesModal
        tickerValue={selectedTicker}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        userId={getUserId()}
      />
    </>
  );
};

export default Opportunities;
