// /// MarketAlerts.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import AlertTable from "./AlertTable";
import AlertEditor from "./AlertEditor";
import { fetchAlerts, deleteAlert, updateAlert, fetchClauses, createAlert, fetchAlertEffectiveness } from "../functions/alertClauseService";
import useFetchDashboardStructure from "../functions/FetchDashboardStructure";

const MarketAlerts = ({ isVisible, onClose, alertSubtypeIdentifier }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(true);
  const [alertsError, setAlertsError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [clauses, setClauses] = useState([]);
  const [alertEffectiveness, setAlertEffectiveness] = useState([]); // Store alert effectiveness data
  const {dashboardStructure, loading: dashboardLoading, error: dashboardError } = useFetchDashboardStructure();
  const [filteredAlerts, setFilteredAlerts] = useState([]);

  // console.log("alertSubtypeIdentifier", alertSubtypeIdentifier)

  // console.log("dasboardstructure in marketalerts:", dashboardStructure)

  // Fetch alert effectiveness data
  const loadAlertEffectiveness = async () => {
    try {
      const effectivenessData = await fetchAlertEffectiveness();
      // console.log("Alert effectiveness data in Market Alerts:", effectivenessData);
      setAlertEffectiveness(effectivenessData);
    } catch (error) {
      console.error("Error fetching alert effectiveness data:", error);
      message.error("Failed to load alert effectiveness data.");
    }
  };

  // Fetch all alerts
  const loadAlerts = async () => {
    setAlertsLoading(true);
    try {
      const data = await fetchAlerts();
      console.log("Alerts data in Market Alerts:", data);
      setAlerts(data);
      setAlertsError(null);
    } catch (error) {
      setAlertsError("Error fetching alerts");
    } finally {
      setAlertsLoading(false);
    }
  };

  useEffect(() => {
    const fetchAlertsData = async () => {
      try {
        await loadAlerts(); // Fetch alerts and set them in state
        loadAlertEffectiveness(); // Fetch effectiveness data
      } catch (error) {
        console.error("Error fetching alerts or effectiveness data:", error);
        setAlertsError("Failed to fetch alerts or effectiveness data.");
      }
    };
  
    if (isVisible) {
      fetchAlertsData(); // Only fetch when the modal is visible
    }
  }, [isVisible]);
  
  useEffect(() => {
    if (alerts.length > 0 && alertSubtypeIdentifier) {
      const identifiers = alertSubtypeIdentifier.split(',').map(id => id.trim());
      // console.log("Identifiers for filtering:", identifiers);
  
      const filtered = alerts.filter(alert => {
        const alertIdentifier = String(alert.ID).trim();
        const matchFound = identifiers.includes(alertIdentifier);
  
        // if (matchFound) {
        //   console.log(`Match found for alert:`, alert); // Log the matched alert
        // } else {
        //   console.log(`No match for alert_subtype_identifier: ${alertIdentifier}`);
        // }
  
        return matchFound;
      });
  
      // console.log("Filtered alerts:", filtered);
      setFilteredAlerts(filtered);
    } else if (alerts.length > 0) {
      setFilteredAlerts(alerts); // No identifier, show all alerts
    }
  }, [alerts, alertSubtypeIdentifier]);
  

  useEffect(() => {
    if (isVisible) {
      loadAlerts(); // Fetch alerts when modal is visible
      loadAlertEffectiveness(); // Fetch alert effectiveness data
    }
  }, [isVisible]);

  const handleEditAlert = async (alertId) => {
    const alertToEdit = alerts.find((alert) => alert.ID === alertId);
    setSelectedAlert(alertToEdit);
    setIsEditing(true);
    try {
      const clauseData = await fetchClauses();
      setClauses(clauseData);
    } catch (error) {
      console.error("Error fetching clauses:", error);
    }
  };

  const handleDeleteAlert = (alertId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this alert?",
      onOk: async () => {
        try {
          await deleteAlert(alertId);
          setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.ID !== alertId));
        } catch (error) {
          console.error("Error deleting alert:", error);
        }
      },
    });
  };

  const handleSaveAlert = async (alertData, action) => {
    try {
      let savedAlert;
      if (action === "update") {
        savedAlert = await updateAlert(alertData);
      } else if (action === "create") {
        savedAlert = await createAlert(alertData);
      }
      await loadAlerts(); // Refresh alerts
      const updatedClauses = await fetchClauses(); // Fetch updated clauses
      setClauses(updatedClauses); // Update clauses in state
  
      if (action === "create") {
        setSelectedAlert(savedAlert); // Set the newly created alert as selected
      }
  
      message.success("Alert saved successfully!");
    } catch (error) {
      console.error("Error saving alert:", error);
      message.error("Failed to save alert.");
    }
  };
  
  

  const handleAddAlert = async () => {
    setSelectedAlert(null);
    setIsEditing(true);
    try {
      const clauseData = await fetchClauses();
      setClauses(clauseData);
    } catch (error) {
      console.error("Error fetching clauses:", error);
    }
  };

  return (
    <Modal
      title="Creating Market Alerts"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
        <Button key="add" type="primary" onClick={handleAddAlert}>
          Add Alert
        </Button>,
      ]}
      width={1500}
    >
      <AlertTable
        alerts={filteredAlerts}
        // alerts={alerts}
        alertsLoading={alertsLoading}
        alertsError={alertsError}
        tableTitle="Market Alerts"
        onEditAlert={handleEditAlert}
        onDeleteAlert={handleDeleteAlert}
        alertEffectiveness={alertEffectiveness} // Pass effectiveness data to AlertTable
      />
      <AlertEditor
        visible={isEditing}
        onClose={() => setIsEditing(false)}
        alertData={selectedAlert}
        onSave={handleSaveAlert}
        clauses={clauses}
        dashboardStructure={dashboardStructure}
        refreshAlerts={loadAlerts} // Pass the loadAlerts function to refresh data
      />

    </Modal>
  );
};

export default MarketAlerts;
