// AlertTestingResultsTable.jsx
import React from 'react';
import { Modal, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../css/AlertTestingResultsTable.css'; // Import custom CSS

const AlertTestingResultsTable = ({ visible, onClose, testResults, columnNames }) => {
    // console.log("testResults in AlertTestingResultsTable", testResults);

    const isAlertData = testResults[0]?.alert_text !== undefined;

    const columns = columnNames.map((colName) => {
        let dataIndex;
        let sorter;
    
        if (colName === 'Clause text' || colName === 'Alert text') {
            dataIndex = isAlertData ? 'alert_text' : 'clause_text';
            sorter = (a, b) =>
                String(a[dataIndex] || '').localeCompare(String(b[dataIndex] || ''));
        } else if (colName === 'Ticker') {
            dataIndex = 'ticker';
            sorter = (a, b) =>
                String(a[dataIndex] || '').localeCompare(String(b[dataIndex] || ''));
        } else if (colName === 'User') {
            dataIndex = isAlertData ? 'userid' : 'user';
            sorter = (a, b) =>
                String(a[dataIndex] || '').localeCompare(String(b[dataIndex] || ''));
        }
    
        return {
            title: (
                <span>
                    {colName} <ExclamationCircleOutlined style={{ marginLeft: 5 }} />
                </span>
            ),
            dataIndex,
            key: dataIndex,
            sorter, // Enable sorting
        };
    });
    

    return (
        <Modal
            title="Test Results"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
            className="alert-testing-modal" // Add custom class
        >
            <Table
                columns={columns}
                dataSource={Array.isArray(testResults) ? testResults : []}
                pagination={{ pageSize: 10 }}
                rowKey={(record) => `${record.ticker}-${isAlertData ? record.userid : record.user}`}
                rowClassName={(record) => (isAlertData ? 'alert-row' : 'clause-row')} // Conditional row class
                onRow={(record) => ({
                    onClick: () => {
                        // Add row click functionality if needed
                    },
                })}
            />
        </Modal>
    );
};

export default AlertTestingResultsTable;