import React, { useState, useEffect } from "react";
import { Table, Spin, Alert, Collapse, Button, Tooltip, Input, Select, message } from "antd";
import epochToDate from "../functions/epochdate";
import { testAlert } from "../functions/alertClauseService";
import AlertTestingResultsTable from "./AlertTestingResultsTable";

const { Panel } = Collapse;

const AlertTable = ({
  alerts,
  alertsLoading,
  alertsError,
  tableTitle,
  onEditAlert,
  onDeleteAlert,
  alertEffectiveness,
}) => {
  const [searchText, setSearchText] = useState(""); // Holds the search text
  const [filteredAlerts, setFilteredAlerts] = useState([]); // Holds the sorted and filtered data
  const [alertTestCount, setAlertTestCount] = useState(0); // Number of alerts from the test
  const [alertTestResults, setAlertTestResults] = useState([]); // Store results for Alert Test
  const [isResultsModalVisible, setIsResultsModalVisible] = useState(false); // Controls modal visibility

  // console.log("alerts in AlertTable ", alerts)
  useEffect(() => {
    if (Array.isArray(alerts) && alerts.length > 0) {
      const sortedAlerts = alerts.slice().sort((a, b) => b.epoch_last_edit - a.epoch_last_edit);
      setFilteredAlerts(sortedAlerts);
      // console.log(filteredAlerts)
    } else {
      setFilteredAlerts([]); // Clear filtered alerts if no data
    }
  }, [alerts]);
  
  

  // Function to handle Alert Test
  const handleTestAlert = async (alertID) => {
    try {
      const result = await testAlert(alertID);
      setAlertTestCount(result.length);
      setAlertTestResults(result);
      setIsResultsModalVisible(true);
      message.success(`Alert ${alertID} tested successfully!`);
    } catch (error) {
      console.error("Error testing alert:", error);
      message.error(`Error testing alert ${alertID}.`);
    }
  };

  // Handle search
  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = alerts.filter((alert) =>
      alert.ID.toString().includes(value) ||
      alert.scope.toLowerCase().includes(value.toLowerCase()) ||
      alert.alert_type.toLowerCase().includes(value.toLowerCase()) ||
      alert.advice_txt.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAlerts(filteredData);
  };

  const formatDate = (epoch) => {
    const fullDate = epochToDate(epoch);
    return fullDate.split(" ")[0];
  };

  const alertColumns = [
    { title: "ID", dataIndex: "ID", key: "ID", sorter: (a, b) => a.ID - b.ID },
    { title: "Scope", dataIndex: "scope", key: "scope", sorter: (a, b) => a.scope.localeCompare(b.scope) },
    { title: "Alert Type", dataIndex: "alert_type", key: "alert_type", sorter: (a, b) => a.alert_type.localeCompare(b.alert_type) },
    {
      title: "Advice Text",
      dataIndex: "advice_txt",
      key: "advice_txt",
      sorter: (a, b) => a.advice_txt.localeCompare(b.advice_txt),
      render: (text) => text,
    },
    { title: "Clauses", dataIndex: "clauses", key: "clauses" },
    {
      title: "Subtype",
      dataIndex: "alert_subtype",
      key: "alert_subtype",
      sorter: (a, b) => a.alert_subtype.localeCompare(b.alert_subtype),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span style={{ color: status === 1 ? "green" : "red", fontWeight: "bold" }}>
          {status === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    { title: "n_total", dataIndex: "n_total", key: "n_total", sorter: (a, b) => (a.n_total || 0) - (b.n_total || 0) },
    {
      title: "Last Edited",
      dataIndex: "epoch_last_edit",
      key: "epoch_last_edit",
      // sorter: (a, b) => b.epoch_last_edit - a.epoch_last_edit, // Descending order: newest first

      // // sorter: (a, b) => a.epoch_last_edit - b.epoch_last_edit, // Correct sorting logic
      // defaultSortOrder: "descend", // Descending to show newest edits first by default

      render: (epoch) => formatDate(epoch),
    },
    
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => onEditAlert(record.ID)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => onDeleteAlert(record.ID)}>
            Delete
          </Button>
          <Button type="link" onClick={() => handleTestAlert(record.ID)}>
            Test
          </Button>
        </>
      ),
    },
  ];

  return (
    <Collapse defaultActiveKey={["1"]} size="small">
      <Panel header={tableTitle} key="1">
        <div style={{ marginBottom: 16 }}>
          <Input.Search
            placeholder="Search alerts"
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: "100%", marginBottom: "16px" }}
            allowClear
          />
        </div>
        {alertsLoading ? (
          <Spin tip="Loading alerts..." />
        ) : alertsError ? (
          <Alert message={alertsError} type="error" />
        ) : (
          <Table
            columns={alertColumns}
            dataSource={filteredAlerts} // Use the pre-sorted data
            pagination={{ pageSize: 5 }}
            rowKey="ID"
            bordered
            size="small"
          />
        )}
        <AlertTestingResultsTable
          visible={isResultsModalVisible}
          onClose={() => setIsResultsModalVisible(false)}
          testResults={alertTestResults}
          columnNames={["Alert text", "Ticker", "User"]}
        />
      </Panel>
    </Collapse>
  );
};

export default AlertTable;
