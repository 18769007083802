// // ESG_DetailsContent.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Table, Tooltip, message } from 'antd';
import api from '../functions/api';
import { useSelector } from 'react-redux';
import '../css/ESG_MainTable.css';
import { getColorForValue } from '../functions/ESG_ColorForValue';

const formatEpochToDate = (epoch) => {
  const date = new Date(epoch * 1000);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}-${month}-${year}`;
};

const ESG_DetailsContent = ({ ticker, details }) => {

  const dataparametersSet = useSelector(
    (state) => state.portfolio?.parametersData?.DP_dataset_json || []
  );

  // console.log("dataparametersSet in esgdetails", dataparametersSet)

  // console.log("ticker in ESG Details", ticker)
  // console.log("details in ESG_DetailsContent", details)

  const [DD_dataset_json, setDD_dataset_json] = useState([]);

  useEffect(() => {
    const fetchDataDeltaForTicker = async () => {
      try {
        const response = await api.post('/Data_Delta_load_for_ticker', { ticker: ticker });
        if (response.status === 200) {
          setDD_dataset_json(response.data.DD_dataset_json);
          // console.log("Data_Delta_load_for_ticker in ESGDETALS", response)
        } else {
          message.error('Failed to fetch Data Delta for the ticker.');
        }
      } catch (error) {
        console.error('Error fetching DD_dataset_json:', error);
        message.error('An error occurred while fetching Data Delta.');
      }
    };
  
    if (ticker) {
      fetchDataDeltaForTicker();
    }
  }, [ticker]);

  const dataFieldMapping = {};
  const dataOrderMapping = {};
  const dataTableTitleMapping = {};

  dataparametersSet.forEach((param) => {
    dataFieldMapping[param.DataField] = {
      ...param,
      source: param.source || null,
    };
    if (param.esg_detail_order !== null && param.esg_detail_order !== undefined) {
      dataOrderMapping[param.DataField] = parseFloat(param.esg_detail_order);
    }
    dataTableTitleMapping[param.DataField] = param.stockinfo_tabletitle;
  });

  const handleValueClick = async (fieldName) => {
    try {
      const response = await api.get('/external_link', {
        params: { ticker, field_name: fieldName },
      });

      const { url } = response.data;
      if (url) {
        window.open(url, '_blank');
      }
    } catch (error) {
      console.error('Error fetching external link:', error);
    }
  };

  const dataSource = Object.keys(details)
  .filter((key) => key !== 'company' && key !== 'ticker')
  .map((key) => {
    const dataDelta = DD_dataset_json.find((item) => item.datafield === key);
    const trend = dataDelta?.trend || null;
    const oldValue = dataDelta?.oldvalue || null;
    const orderValue = dataOrderMapping[key] ?? Infinity;

    // Get the base tooltip from dataparametersSet
    let tooltip = dataparametersSet.find((param) => param.DataField === key)?.Tooltip || '';

    // Extend tooltip if trend exists
    if (trend && (trend === 'positive' || trend === 'negative')) {
      tooltip += ` The trend is ${trend} as the previous value was ${oldValue}.`;
    }

    return {
      key,
      metric: dataFieldMapping[key]?.Label || key,
      value: details[key],
      order: orderValue,
      tooltip, // Set extended tooltip here
      tableTitle: dataTableTitleMapping[key],
      epoch: details[`${key}_epoch`] || null,
      trend,
    };
  })
  .filter((item) => item.tableTitle !== undefined)
  .sort((a, b) => a.order - b.order);





  const groupedData = dataSource.reduce((acc, item) => {
    const { tableTitle } = item;
    if (!acc[tableTitle]) acc[tableTitle] = [];
    acc[tableTitle].push(item);
    return acc;
  }, {});

  const columns = [
    {
      dataIndex: 'metric',
      key: 'metric',
      render: (text, record) => {
        const source = dataFieldMapping[record.key]?.source || '';
        const epochDate = record.epoch ? formatEpochToDate(record.epoch) : '';
  
        return (
          <Tooltip title={record.tooltip} mouseEnterDelay={0.2}>
            <div>
              <span>{text}</span>
              {source && (
                <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
                  {source}
                  {epochDate && ` (${epochDate})`}
                </div>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => {
        let valueToDisplay;
  
        if (text === null || text === undefined || text === '' || text === 0) {
          valueToDisplay = 'N/A';
        } else if (typeof text === 'string' && text.toLowerCase() === 'n/a') {
          valueToDisplay = 'N/A';
        } else {
          valueToDisplay = text;
        }
  
        const color = getColorForValue(record.key, valueToDisplay, dataFieldMapping);
        const className = `cell-${color}`;
        const trendComponent =
          record.trend === 'positive' ? (
            <span style={{ color: 'green' }}> ↑</span>
          ) : record.trend === 'negative' ? (
            <span style={{ color: 'red' }}> ↓</span>
          ) : null;
  
        return (
          <div
            className={className}
            onClick={() => handleValueClick(record.key)}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            {valueToDisplay}
            {trendComponent}
          </div>
        );
      },
    },
  ];
  
  

  return (
    <div id="ESG_Details-content">
      <div className="top-tables">
        {['ESG Risk Rating', 'ESG Industry Ranking'].map((tableTitle) => (
          <div key={tableTitle} className="table-wrapper">
            <h3>{tableTitle}</h3>
            <Table
              columns={columns}
              dataSource={groupedData[tableTitle]}
              pagination={false}
              rowKey={(record) => record.key}
              showHeader={false}
              size="small"
            />
          </div>
        ))}
      </div>
      <div className="bottom-tables">
        {['Environment', 'Social', 'Governance'].map((tableTitle) => (
          <div key={tableTitle} className="table-wrapper">
            <h3>{tableTitle}</h3>
            <Table
              columns={columns}
              dataSource={groupedData[tableTitle]}
              pagination={false}
              rowKey={(record) => record.key}
              showHeader={false}
              size="small"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ESG_DetailsContent;
