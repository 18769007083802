// AlertEditor.jsx

import React, { useState, useEffect, useMemo } from "react";
import { Modal, Form, Select, Input, Button, Collapse, Table, message, Space, AutoComplete, Switch } from "antd";
import TextCrafter from './TextCrafter';
import ClauseEditor from "./ClauseEditor";
import AlertTestingResultsTable from "./AlertTestingResultsTable";
import { testClause, testAlert, copyAlert } from "../functions/alertClauseService";


const { Panel } = Collapse;

const AlertEditor = ({ visible, onClose, alertData, onSave, clauses, dashboardStructure, refreshAlerts }) => {

  const [form] = Form.useForm();
  const [isTextCrafterVisible, setIsTextCrafterVisible] = useState(false);
  const [craftedText, setCraftedText] = useState(alertData?.advice_txt || "");
  const [isClauseEditorVisible, setIsClauseEditorVisible] = useState(false);
  const [editingClause, setEditingClause] = useState(null);
  const [testResults, setTestResults] = useState({});
  const [isTestResultsModalVisible, setIsTestResultsModalVisible] = useState(false); // Modal visibility for AlertTestingResultsTable
  const [currentTestResults, setCurrentTestResults] = useState([]); // Store results for display in modal
  const [alertTestCount, setAlertTestCount] = useState(null);
  // const [alertTestCount, setAlertTestCount] = useState(0); // Number of alerts from the test
  const [alertTestResults, setAlertTestResults] = useState([]); // Store results for Alert Test
  const [isTestingAlert, setIsTestingAlert] = useState(false); // Spinner state for Alert Test

// console.log("alertData in AlertEditor ", alertData)
// console.log("dasboardstructure in alerteditor", dashboardStructure )

const handleCopyAlert = () => {
  Modal.confirm({
    title: "Are you sure?",
    content: "Do you want to create a copy of this alert?",
    okText: "Yes",
    cancelText: "No",
    onOk: async () => {
      try {
        const response = await copyAlert(alertData);
        message.success("Alert copied successfully!");
        if (refreshAlerts) {
          refreshAlerts(response); // Refresh alerts with the newly created alert
        }
      } catch (error) {
        console.error("Error copying alert:", error);
        message.error("Failed to copy alert.");
      }
    },
  });
}; 

// Function to handle Alert Test and save results
const handleTestAlert = async () => {
  setIsTestingAlert(true); // Start spinner
  setAlertTestCount(null); // Clear results while testing
  try {
    const result = await testAlert(alertData.ID);
    setAlertTestCount(result.length);
    setAlertTestResults(result);
    message.success('Alert tested successfully!');
  } catch (error) {
    console.error("Error testing alert:", error);
    message.error('Error testing alert.');
  } finally {
    setIsTestingAlert(false); // Stop spinner
  }
};

// Open AlertTestingResultsTable with alert test results
const handleOpenAlertTestResultsModal = () => {
  setCurrentTestResults(alertTestResults);
  setIsTestResultsModalVisible(true);
};
//   console.log('clauses in AlertEditor ', clauses)
//   console.log("dashboardStructure in AlertEditor ", dashboardStructure)
// console.log("alertData in ALertEditor ", alertData)
// console.log("clauses in AlertEditor ", clauses)
  
  const [availableClauses, setAvailableClauses] = useState([]); // Assuming this gets populated from props or an API
  useEffect(() => {
    setAvailableClauses(
      clauses.map((clause) => ({
        value: clause.ID.toString(),  // Convert ID to string for consistency
        label: `ID: ${clause.ID}, ${clause.clause_txt || "No text available"}`, // Default text if `clause_txt` is undefined
      }))
    );
}, [clauses]);

const [localClauses, setLocalClauses] = useState(
    alertData && Array.isArray(alertData.clauses)
      ? alertData.clauses
      : String(alertData?.clauses || "")
          .split(",")
          .map((id) => parseInt(id, 10))  // Ensure IDs are numbers
          .filter((id) => !isNaN(id))  // Filter out any invalid IDs
  );
  

  // Handler for adding an existing clause
const handleAddExistingClause = (clauseId) => {
    const clauseToAdd = clauses.find((clause) => clause.ID === parseInt(clauseId, 10));

    if (clauseToAdd && !localClauses.includes(clauseToAdd.ID)) {
        const updatedClauses = [...localClauses, clauseToAdd.ID];
        
        // Update both localClauses and associatedClauses to trigger re-render
        setLocalClauses(updatedClauses);
    }
  };
  
  
  const handleAddNewClause = () => {
    setEditingClause({}); // Open ClauseEditor with a blank clause object
    setIsClauseEditorVisible(true);
  };
  
  const handleNewClauseSaved = (newClause) => {
    if (newClause) {
      // Add new clause ID to localClauses
      setLocalClauses((prevClauses) => {
        const updatedClauses = [...new Set([...prevClauses, newClause.ID])]; // Ensure unique IDs
        form.setFieldsValue({ clauses: updatedClauses.join(",") });
        return updatedClauses;
      });
  
      // Add new clause to availableClauses if not already present
      setAvailableClauses((prevAvailableClauses) => {
        const exists = prevAvailableClauses.some(
          (clause) => parseInt(clause.value, 10) === newClause.ID
        );
        if (!exists) {
          return [
            ...prevAvailableClauses,
            {
              value: newClause.ID.toString(),
              label: `ID: ${newClause.ID}, ${newClause.clause_txt || "No text available"}`,
            },
          ];
        }
        return prevAvailableClauses;
      });
    }
  };
  



useEffect(() => {
  if (alertData) {
    form.setFieldsValue({
      ...alertData,
      status: alertData.status === 1, // Convert status to boolean for the Switch
    });
    setCraftedText(alertData.advice_txt || "");
    setLocalClauses(
      alertData.clauses
        ? alertData.clauses.split(",").map((id) => parseInt(id, 10)).filter((id) => !isNaN(id))
        : []
    );
  } else {
    form.resetFields();
    setCraftedText("");
    setLocalClauses([]);
  }
}, [alertData, form]);



  // Sync `craftedText` with `alertData.advice_txt` only if `advice_txt` changes
  useEffect(() => {
      setCraftedText(alertData?.advice_txt || "");
    }, [alertData?.advice_txt]);

  useEffect(() => {
        if (alertData && alertData.clauses) {
            setLocalClauses(
                alertData.clauses.split(',').map(id => parseInt(id, 10)).filter(id => !isNaN(id))
            );
        } else {
            setLocalClauses([]); // Reset if there are no clauses
        }
    }, [alertData]);

   


    const associatedClauses = useMemo(() => {
      const allClauses = clauses.filter((clause) => localClauses.includes(clause.ID));
      const uniqueClauses = Array.from(new Map(allClauses.map((clause) => [clause.ID, clause])).values());
      return uniqueClauses;
    }, [clauses, localClauses]);
    
    
    


      const handleSave = () => {
        form.validateFields().then((values) => {
          const updatedData = { 
            ...alertData, 
            ...values, 
            advice_txt: craftedText, 
            clauses: localClauses.join(","), // Ensure clauses are saved as a comma-separated string
            status: values.status ? 1 : 0, // Map Switch state to database values (1 or 0)
          };
      
          if (!updatedData.ID) {
            // For new alert creation
            onSave(updatedData, "create");
          } else {
            // For updating an existing alert
            onSave(updatedData, "update");
          }
      
          // Refresh alerts after saving
          if (refreshAlerts) {
            refreshAlerts(updatedData); // Pass the updated alert data to refresh
          }
      
          message.success("Alert saved successfully!");
          // onClose(); // Optionally close the modal
        }).catch((error) => {
          console.error("Validation failed:", error);
        });
      };
      
      
      
  

  const openTextCrafter = () => {
    setIsTextCrafterVisible(true);
  };

  const handleTextCrafterSave = (newText) => {
    setCraftedText(newText);
    setIsTextCrafterVisible(false);
    form.setFieldsValue({ advice_txt: newText });
  };

  // Updated handleTestClause to store count and array separately
const handleTestClause = async (clause) => {
  try {
      const result = await testClause(clause); // Assume `result` is an array of test results
      const resultCount = result.length;

      setTestResults((prevResults) => ({
          ...prevResults,
          [clause.ID]: { count: `${resultCount} True`, results: result } // Store count and results array
      }));

      message.info(`${resultCount} True`); // Show a message with count of True results

  } catch (error) {
      console.error("Error in handleTestClause:", error);
  }
};

// Updated handleOpenTestResultsModal to set currentTestResults correctly
const handleOpenTestResultsModal = (clauseId) => {
  const clauseData = testResults[clauseId];
  setCurrentTestResults(clauseData?.results || []); // Set the results array for modal
  setIsTestResultsModalVisible(true);
};

  const handleEditClause = (clause) => {
    setEditingClause(clause);
    setIsClauseEditorVisible(true);
  };

  const handleDisassociateClause = (clauseId) => {
    // Filter out the disassociated clause ID from alertData.clauses
    const updatedClauses = localClauses.filter((id) => id !== clauseId);
  
    // Update `localClauses` to keep internal state in sync
    setLocalClauses(updatedClauses);
};


  // Define columns for the clauses table
  // In AlertEditor, update clauseColumns
const clauseColumns = [
  { title: "Clause ID", dataIndex: "ID", key: "ID" },
  { title: "Field", dataIndex: "clause_field", key: "clause_field" },
  { title: "Operator", dataIndex: "clause_operator", key: "clause_operator" },
  { 
    title: "Clause Text", 
    dataIndex: "clause_txt", 
    key: "clause_txt",
    render: (text, clause) => (
      <>
        <div>{text}</div>
        {testResults[clause.ID]?.count && (
          <div>
            <a 
              onClick={() => handleOpenTestResultsModal(clause.ID)} 
              style={{ color: "blue", cursor: "pointer" }}
            >
              {testResults[clause.ID].count} {/* Display link like "6 True" */}
            </a>
          </div>
        )}
      </>
    )
  },
  { title: "Threshold Field", dataIndex: "threshold_field", key: "threshold_field" },
  { title: "Source Table", dataIndex: "clause_field_sourcetable", key: "clause_field_sourcetable" },
  {
    title: "Action",
    key: "action",
    render: (_, clause) => (
      <>
        <Button type="link" onClick={() => handleTestClause(clause)}>Test</Button>
        <Button type="link" onClick={() => handleEditClause(clause)}>Edit</Button>
        <Button type="link" danger onClick={() => handleDisassociateClause(clause.ID)}>Disassociate</Button>
      </>
    ),
  },
];

const handleOk = () => {
  if (refreshAlerts) {
    refreshAlerts(); // Trigger refresh in MarketAlerts.jsx
  }
  onClose(); // Close the AlertEditor modal
};

  return (
    <Modal
      title={`Edit Alert ${alertData?.ID ? `(${alertData.ID})` : ""}`}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Space key="footerActions">
          <Button key="alertTest" onClick={handleTestAlert} loading={isTestingAlert}>
            {isTestingAlert ? "Testing..." : "Alert Test"}
          </Button>
          {alertTestCount === 0 && !isTestingAlert && (
            <span style={{ color: "gray" }}>No alerts</span>
          )}
          {alertTestCount > 0 && !isTestingAlert && (
            <a
              onClick={handleOpenAlertTestResultsModal}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {alertTestCount} Alerts
            </a>
          )}
          {alertTestCount === null && !isTestingAlert && (
            <span style={{ color: "gray" }}>&nbsp;</span> // Empty space when no test is run yet
          )}
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button key="copy" type="primary" onClick={handleCopyAlert}>
            Copy Alert
          </Button>
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>
        </Space>
      ]}
      
      
      width={2000}
    >
      <Form form={form} layout="vertical">
        {/* Form Items for Alert Fields */}
        <Form.Item
  label="Status"
  name="status"
  valuePropName="checked" // Maps the Switch's checked state to true/false
  rules={[{ required: true, message: "Status is required" }]}
>
  <Switch
    checkedChildren="Active"
    unCheckedChildren="Inactive"
    defaultChecked={alertData?.status === 1}
    onChange={(checked) => form.setFieldsValue({ status: checked ? 1 : 0 })} // Set 1 for Active, 0 for Inactive
  />
</Form.Item>

        <Form.Item
          label="Scope"
          name="scope"
          rules={[{ required: true, message: "Scope is required" }]}
        >
          <Select placeholder="Select scope">
            <Select.Option value="Portfolio">Portfolio</Select.Option>
            <Select.Option value="Favorites">Favorites</Select.Option>
            <Select.Option value="General">General</Select.Option>
            <Select.Option value="Unknown">Unknown</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Alert Type"
          name="alert_type"
          rules={[{ required: true, message: "Alert type is required" }]}
        >
          <Input placeholder="Enter alert type" />
        </Form.Item>

        <Form.Item
          label="Alert Subtype"
          name="alert_subtype"
          rules={[{ required: true, message: "Alert subtype is required" }]}
        >
          <Input placeholder="Enter alert subtype" />
        </Form.Item>

        <Form.Item
          label="Advice Text"
          name="advice_txt"
          rules={[{ required: true, message: "Advice text is required" }]}
        >
          <Input.TextArea
            rows={3}
            placeholder="Enter advice text"
            value={craftedText}
            onClick={openTextCrafter}
            readOnly
          />
        </Form.Item>

        <Form.Item
  label="Clauses"
  name="clauses"
  rules={[
    {
      required: true,
      message: "Clauses are required",
      validator: (_, value) =>
        localClauses.length > 0
          ? Promise.resolve()
          : Promise.reject(new Error("At least one clause is required")),
    },
  ]}
  // rules={[{ required: true, message: "Clauses are required" }]}
>
  <Input 
    placeholder="Enter clause IDs separated by commas"
    value={localClauses.join(",")}  // Show clauses as a comma-separated string
    onChange={(e) => setLocalClauses(
      e.target.value.split(",").map((id) => parseInt(id.trim(), 10)).filter((id) => !isNaN(id))
    )}  // Convert the input back to an array of integers
  />
</Form.Item>


        {/* Collapsible section for associated clauses */}
     
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 16 }}>
          <Panel 
            header={
              <Space>
                Associated Clauses
                <AutoComplete
                        style={{ width: 500 }}
                        options={availableClauses}  // directly use availableClauses here
                        onSelect={handleAddExistingClause}
                        placeholder="Add Existing Clause"
                        filterOption={(inputValue, option) =>
                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                        }
                    />
                <Button onClick={handleAddNewClause}>Add a NEW Clause</Button>
              </Space>
            }
            key="1"
          >
            <Table
              columns={clauseColumns}
              dataSource={associatedClauses}
              pagination={false}
              rowKey="ID"
              bordered
            />
          </Panel>
        </Collapse>
      
      </Form>

      {/* TextCrafter Modal */}
      {isTextCrafterVisible && (
        <TextCrafter
          initialText={craftedText}
          onSave={handleTextCrafterSave}
          onClose={() => setIsTextCrafterVisible(false)}
          dashboardStructure={dashboardStructure}
        />
      )}

      {/* ClauseEditor Modal */}
      {isClauseEditorVisible && (
        <ClauseEditor
          visible={isClauseEditorVisible}
          onClose={() => setIsClauseEditorVisible(false)}
          clause={editingClause}
          dashboardStructure={dashboardStructure}
          refreshClauses={handleNewClauseSaved}  // Use handleNewClauseSaved to add new clause
        
        />
      )}

      {/* AlertTestingResultsTable Modal for Clause Test Results*/}
      <AlertTestingResultsTable
        visible={isTestResultsModalVisible}
        onClose={() => setIsTestResultsModalVisible(false)}
        testResults={Array.isArray(currentTestResults) ? currentTestResults : []}
        // testResults={currentTestResults}
        columnNames={['Clause text', 'Ticker', 'User']}
      />
      {/* AlertTestingResultsTable Modal for Alert Test Results */}
      <AlertTestingResultsTable
    visible={isTestResultsModalVisible}
    onClose={() => setIsTestResultsModalVisible(false)}
    testResults={Array.isArray(currentTestResults) ? currentTestResults : []}
    columnNames={['Alert text', 'Ticker', 'User']} // Ensure data keys match expected structure in testResults
/>


    </Modal>
  );
};

export default AlertEditor;
