import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Badge } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import PF_Alerts from './PF_Alerts';
import Opportunities from './Opportunities'; // Import the new component
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

const Alerts = ({ disabled }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const hasUnseenAlerts = useSelector(state =>
    state.portfolio?.data?.pf_alert?.some(alert => alert.seen === 0)
  );

  return (
    <>
      <Badge dot={hasUnseenAlerts} color="red">
        <Button type="primary" onClick={showModal} disabled={disabled}>
          Alerts
        </Button>
      </Badge>
      <Modal
        title="Alerts"
        visible={isVisible}
        onCancel={handleClose}
        width={1400}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>
            OK
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Portfolio Alerts" key="1">
            <PF_Alerts />
          </TabPane>
          <TabPane tab="Opportunities Alerts" key="2">
            <Opportunities />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Alerts;
